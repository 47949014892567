#contact{
    overflow-y: scroll;
    height: 100vh;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.72), rgba(0, 0, 0, 0.72)),url('../../Assets/landing.jpg');
    background-position: bottom center;
    background-size: cover;
}
.contact-container{
    padding-top: 60px;
    padding-left: 70px;
    justify-content: center;
}
.contact-container > div{
    margin: auto;
}
.ff-title{
    display: none;
}
.text-box-contact{
    display: flex;
    text-align: left;
    justify-content: center;
    padding: 100px;
}
.list-com > li{
    padding-top: 50px;
}
.middle-li{
    padding-bottom: 50px;
    padding-top: 50px;
    border-bottom: 1px solid white;
    border-top: 1px solid white;

}

#Publish1FAIpQLSd8G-oFVa7KLssF57NPB0yxhlzIE8wkVlvMlebceTTJmt0XVw{
    margin: 0px;
    backdrop-filter: blur(4px);
    background-color: white;
    border: solid 1px black;
    border-radius: 0px;
}
label{
    font-family: Antic Didone;
    font-weight: 600 !important;
    font-size: 18px !important;
    position: relative;	
    font-size: 30px;
}
@media only screen and (max-width: 1421px){
    .contact-container{
        padding: 0px;
    }

}
@media only screen and (max-width: 991px){
    #ff-compose{
        display: flex;
        justify-content: center;
    }

}